<script setup lang="ts">
import PropType from "#imports";
import type {IButtonUnitOptions} from "~/interfaces/units/IButtonUnitOptions";

defineProps({
	options: {
		type: Object as PropType<IButtonUnitOptions>,
		required: true,
	},
});
const emits = defineEmits(['clickOnBtn']);
</script>

<template>
	<button
			class="button-unit"
			:type="options?.type ?? 'button'"
			:class="{
			[options?.class ?? '']: true,
			'button-unit--secondary': options?.color === 'secondary',
			'button-unit--success': options?.color === 'success',
			'button-unit--error': options?.color === 'error',
			'button-unit--info': options?.color === 'info',
			'button-unit--warning': options?.color === 'warning',
			'only-icon': !options?.hasOwnProperty('text'),
		}"
			:disabled="options?.disabled"
			@click.prevent="emits('clickOnBtn')"
	>
        <span class="button-unit__icon">
            <slot name="icon"></slot>
        </span>
		<span v-if="options?.text?.length !== 0">
            {{ options?.text }}
        </span>
	</button>
</template>

<style scoped>

</style>

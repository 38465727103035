<script setup lang="ts">
import PropType, {ref} from "#imports";
import type {IInputUnitOptions} from "~/interfaces/units/IInputUnitOptions";
import type {Ref} from "vue";

const tooltipDisplay: Ref<boolean> = ref(false);
const focused: Ref<boolean> = ref(false);

defineProps({
	modelValue: {
		type: [String, Number],
		default: '',
		required: false,
	},
	options: {
		type: Object as PropType<IInputUnitOptions>,
		required: true,
	},
});

const emits = defineEmits(['update:modelValue', 'fileChange']);

const onUpdateInput = ($event: any) => {
	emits('update:modelValue', $event?.target?.value);
}

const onChangeInput = ($event: any) => {
	emits('fileChange', $event);
}

const onfocusOnInput = (): void => {
	const inputRef = ref<HTMLInputElement | null>(null);
	if (inputRef.value) {
		inputRef.value.focus();
	}
}
</script>

<template>
	<label v-if="options?.hasOwnProperty('name') && options?.name?.length > 0" :for="options?.slug" class="input-unit__label" :class="{'required': options?.required}">
		<span>{{ options?.name }}</span>
		<span v-if="options?.helper" class="vue-select-unit__label--secondary">
					<i class="fa-regular fa-circle-question" @mouseover="tooltipDisplay = true" @mouseleave="tooltipDisplay = false"></i>
					<div class="tooltip" :class="tooltipDisplay ? 'tooltip--show' : 'tooltip--hide'">{{ options?.helper }}</div>
				</span>
		<span v-if="options.hasOwnProperty('secondaryLabel') && options?.secondaryLabel?.length !== 0" class="input-unit__label--secondary">
	                {{ options?.secondaryLabel }}
	            </span>
	</label>
	<div
			class="input-unit__container"
			:class="{
					[options?.class ?? '']: true,
					'focused': focused,
					'readonly': options?.readonly ?? false,
					'input-unit--success': options?.successState ?? false,
					'input-unit--error': options?.errorState ?? false,
					'input-unit--warning': options?.warningState ?? false,
					'input-unit--info': options?.infoState ?? false,
				}"
	>
				<span class="input-unit__prefix" @click="onfocusOnInput">
					<slot name="prefix"></slot>
				</span>
		<input
				ref="input"
				:type="options?.type ?? 'text'"
				:name="options?.slug"
				:id="options?.slug"
				class="input-unit"
				:v-maska="options?.mask"
				:data-maska="options?.mask"
				:value="modelValue"
				:placeholder="options?.placeholder ?? ''"
				:minlength="options?.minlength ?? ''"
				:maxlength="options?.maxlength ?? ''"
				:pattern="options?.pattern ?? ''"
				:min="options?.min ?? ''"
				:max="options?.max ?? ''"
				:step="options?.step ?? ''"
				:accept="options?.accept ?? ''"
				:required="options?.required ?? false"
				:readonly="(options?.readonly || options?.class?.includes('only-text')) ?? false"
				:autocomplete="options?.autocomplete ?? ''"
				@input="onUpdateInput($event)"
				@change="onChangeInput($event)"
				@focus="focused=true"
				@blur="focused=false"
		/>
		<span class="input-unit__suffix" @click="onfocusOnInput">
					<slot name="suffix"></slot>
		</span>
	</div>

	<small
			v-show="options?.hasOwnProperty('infoText') && options?.infoText?.length !== 0"
			:id="options?.slug+'_info'"
			class="mt-1 input-unit__label--secondary"
	>
		{{ options?.infoText }}
	</small>
</template>

<style scoped>

</style>
